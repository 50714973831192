import { get, post, formPost } from '@/api/server'

// 获取手机验证码
export function apiGetPhoneCode(data) {
  return post({
    url: '/webcompany/sendmsg',
    data,
  })
}

// === 我的账户 ===
// 账户信息 -> 修改
export function apiChangeUserInfo(data) {
  return post({
    url: '/webcompany/updateuserinfo',
    data,
  })
}

// 账户信息 -> 离线申请码
export function apiGetLeaveCode(data) {
  return post({
    url: '/webcompany/getNxi',
    data,
  })
}

// 注销账户 -> 确认注销
export function apiConfirmCancelled(data) {
  return post({
    url: '/webcompany/usercannel',
    data,
  })
}

// 修改密码 -> 获取图片验证码
export function apiImgCode(data) {
  return post({
    url: '/common/codenew.php',
    data,
  })
}

// 修改密码 -> 验证验证码
export function apiCodeVerification(data) {
  return post({
    url: '/webcompany/codeverification',
    data,
  })
}

// 修改密码 -> 验证新密码
export function apiCheckNewPwd(data) {
  return post({
    url: '/webcompany/updatepassworld',
    data,
  })
}

// 绑定微信 -> 绑定微信手机验证码检测
export function apiBindWxCodeCheck(data) {
  return post({
    url: '/webcompany/verifyphone',
    data,
  })
}

// 绑定微信 -> 扫码后的回调
export function apiScanWxQaBack(data) {
  return post({
    url: '/webcompany/verifybindweixin',
    data,
  })
}

// 绑定微信 -> 强制绑定
export function apiForceBindWx(data) {
  return post({
    url: '/webcompany/forcebind',
    data,
  })
}

// 绑定微信 -> 解除绑定
export function apiRelieveWxBind(data) {
  return post({
    url: '/webcompany/cannel_weixin_bind',
    data,
  })
}

// === 我的VIP ===
// 购买记录
export function apiGetpurchaseRecord(data) {
  return post({
    url: '/webcompany/getorders',
    data,
  })
}

// 开具发票 -> 获取列表
export function apiGetOrderskplist(data) {
  return post({
    url: '/webcompany/orderskplist',
    data,
  })
}

// 开具发票 -> 查看详情
export function apiOrderSeeDetail(data) {
  return post({
    url: '/webcompany/getinvoice',
    data,
  })
}

// 开具发票 -> 开具发票
export function apiUserInvoice(data) {
  return post({
    url: '/webcompany/invoicekp',
    data,
  })
}

export function apiUserInvoice360(data) {
  return post({
    url: '/webcompany/Invoicekp360',
    data,
  })
}

// 开具发票 -> 上传开票资质
export function apiUpLoadFlair(data) {
  return formPost({
    url: '/oss/upload_kpzg',
    data,
    timeout: 100000,
  })
}

// 授权证书 -> 获取是否有授权证书栏目
export function apiCanGetCertificate(data) {
  return post({
    url: '/webcompany/get_ca',
    data,
  })
}

// 授权证书 -> 获取授权证书内容
export function apiGetCertificate(data) {
  return post({
    url: '/webcompany/certificate',
    data,
  })
}

// 授权证书 -> 第一次添加证书
export function apiAddCertificate(data) {
  return post({
    url: '/webcompany/addcertificate',
    data,
  })
}

// 授权证书 -> 修改证书名称
export function apiCertificateUpdateName(data) {
  return post({
    url: '/webcompany/update_ca',
    data,
  })
}

// === 我的精品图库 ===
// 图库状态
export function apiGetGalleryInfo(data) {
  return post({
    url: '/webcad/gallerystatus',
    data,
  })
}

// 购买记录
export function apiGetGalleryRecordList(data) {
  return post({
    url: '/webcompany/getgallerylist',
    data,
  })
}

// 开具发票 -> 获取列表
export function apiGetGalleryOrderInfo(data) {
  return post({
    url: '/webcompany/galleryorderinfo',
    data,
  })
}

// === 批量购买 ===
// 我的授权码
export function apiGetMyCdkList(data) {
  return post({
    url: '/webcompany/getmycdklist',
    data,
  })
}

// 我的授权码 -> 修改账户或名称
export function apiUpdateCdKey(data) {
  return post({
    url: '/webcompany/updateCDKey',
    data,
  })
}

// 激活授权码
export function apiConsumeCdKey(data) {
  return post({
    url: '/webcompany/consumecdkey',
    data,
  })
}

// 账号解锁 -> 验证验证码
export function apiCheckUnfreezeTwoCode(data) {
  return post({
    url: '/webcad/codeverificationnew',
    data,
  })
}

// 账号解锁 -> 验证新密码
export function apiUnfreezeCheckNewPwd(data) {
  return post({
    url: '/webcompany/rpcheck',
    data,
  })
}
